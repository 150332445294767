body {
    background-color: #f0f5f9;
}

//main content css
.main-content {
    margin: 50px 0px 0px 220px;
    transition: margin ease-in-out 0.3s;

    &.collapsed {
        margin: 50px 0px 0px 55px;
    }
}


// change color based on url
:root {
    --btn-primary-bg-color: #007bff;
}

.btn-primary {
    background-color: var(--btn-primary-bg-color);
    border: none;
    padding: 6px 12px;
}

.btn-primary.special {
    background-color: #a73485 !important;
    border: none;
    padding: 6px 12px;
}


.form-control {
    border: 1px solid #cccccc;
}


// filter
.filter-type {
    font-size: .75rem;
    line-height: 20px;
    background-color: #e2e8f0;
    max-width: 32px;
    max-height: 20px;
    text-align: center;
    padding: 0;
    border-radius: 5px;
    border: 1px solid #cbd5e1;
    color: #475569;
    position: absolute;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center
}


// Navbar
.navbar.admin {
    background-color: #FFF;
    height: 55px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    box-shadow: 2px -3px 8px black;

    .logo {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        padding-left: 15px;

        img {
            width: 50px;
        }
    }

    .site>a {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    p {
        font-size: 19px;
        font-weight: 500;
    }

    .notification-icon:hover+.notifications {
        // display: block;
    }

    .notifications {
        // display: none;
        width: 250px;
        border-radius: 5px;
        background-color: #ffffff;
        top: 40px;
        right: 0;
        box-shadow: 1px 2px 7px -2px black;
        padding: 8px;

        &::after {
            right: 44px;
            border: solid 8px transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: #dee2e6;
            top: 0;
            margin-top: -17px;
        }

        hr {
            margin: 5px 0;
        }
    }
}

/* WebKit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: #344766;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #2b4672;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #344766 transparent;
}

*::-moz-selection {
    background-color: #344766;
}

/* Microsoft Edge */
* {
    -ms-overflow-style: none;
}

*::-ms-scrollbar-thumb {
    background-color: #344766;
}

*::-ms-scrollbar-track {
    background-color: transparent;
}

// side menu bar
.sidebar {
    padding-top: 60px;
    position: fixed;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #1e293b;
    //background-color: #29306e;
    z-index: 2;
    box-shadow: -5px -1px 9px black;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: width ease-in-out 0.3s;

    &.collapsed {
        width: 55px;

        li.mainmenu {
            padding: 7px 12px;
            position: relative;
        }

        .submenu-arrow {
            display: none;
        }

        .submenu {
            padding: 5px 0 5px 45px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.6s ease;
            right: -50px;
            position: absolute;

            a {
                li {
                    padding: 5px;
                    color: #b4b4b4;

                }
            }
        }

        /* Added CSS to show submenu on hover */
        li.mainmenu:hover .submenu {
            max-height: 300px;
            /* Change the height as per your need */
        }
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            color: #FFF;
        }
    }

    li.mainmenu {
        list-style-type: none;
        padding: 7px 20px;
        margin: 0 0 4px 4px;
        cursor: pointer;
        position: relative;

        .menu-icon {
            margin-right: 1rem;
            color: #c9c9c9;
        }

        &:hover,
        &.active {
            background-color: #344766;
            //background-color: #3d4a85;
            border-radius: 4px;

            .menu-icon {
                color: #FFF;
            }
        }
    }

    .submenu-arrow {
        position: absolute;
        right: 14px;
        top: 11px;
        transform-origin: center;
        transition: transform 0.6s ease;
        font-size: 30px;
        opacity: 0.6;
        width: 18px;
    }

    .submenu {
        padding: 5px 0 5px 45px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.6s ease;
        position: relative;

        &::before {
            height: calc(100% - 12px);
            transition: height .5s;
            position: absolute;
            content: "";
            width: 1px;
            background: hsla(220, 3%, 80%, .57);
            top: 6px;
            left: 27px;
        }

        a {
            position: relative;

            li {
                padding: 5px;
                color: #b4b4b4;

                &.active,
                &:hover {
                    color: #FFF;

                    &::before {
                        background: #FFF;
                    }

                    &::after {
                        border-top-color: #FFF;
                    }
                }

                &::before {
                    background: #b4b4b4;
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -6px;
                    border-radius: 100%;
                }

                &::after {
                    position: absolute;
                    content: "";
                    top: 48%;
                    left: -17px;
                    width: 14px;
                    border-top: 1px solid #b4b4b4;
                }
            }
        }
    }

    &.special-background {
        background-color: #29306e; // Example special color

        li.mainmenu {

            &:hover,
            &.active {
                background-color: #3d4a85; // Example special hover color
            }
        }
    }
}




//setup sidebar
.setup-sidebar {
    padding-top: 60px;
    position: fixed;
    width: 0;
    // text-wrap: nowrap;
    top: 0;
    left: 220px;
    bottom: 0;
    left: 0;
    background-color: #1e293b;
    //background-color: #29306e;
    opacity: 0;
    z-index: 2;
    box-shadow: -5px -1px 9px black;
    overflow-y: scroll;
    transition: width 0.6s ease-in-out, opacity 0.6s ease-in-out;

    &.open {
        width: 220px;
        opacity: 1;
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            color: #FFF;
        }
    }

    li.mainmenu {
        list-style-type: none;
        padding: 7px 20px;
        margin: 0 0 4px 4px;
        cursor: pointer;
        position: relative;

        .menu-icon {
            margin-right: 1rem;
            color: #c9c9c9;
        }

        &:hover,
        &.active {
            // background-color: #3d4a85;
            background-color: #344766;
            border-radius: 4px;

            .menu-icon {
                color: #FFF;
            }
        }
    }

    .submenu-arrow {
        position: absolute;
        right: 14px;
        top: 11px;
        transform-origin: center;
        transition: transform 0.6s ease;
        font-size: 30px;
        opacity: 0.6;
        width: 18px;
    }


    .submenu {
        padding: 5px 0 5px 45px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.6s ease;
        position: relative;

        &::before {
            height: calc(100% - 12px);
            transition: height .5s;
            position: absolute;
            content: "";
            width: 1px;
            background: hsla(220, 3%, 80%, .57);
            top: 6px;
            left: 27px;
        }

        a {
            position: relative;


            li {
                padding: 5px;
                color: #b4b4b4;

                &.active,
                &:hover {
                    color: #FFF;

                    &::before {
                        background: #FFF;
                    }

                    &::after {
                        border-top-color: #FFF;
                    }
                }

                &::before {
                    background: #b4b4b4;
                    position: absolute;
                    content: "";
                    width: 5px;
                    height: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -6px;
                    border-radius: 100%;
                }

                &::after {
                    position: absolute;
                    content: "";
                    top: 48%;
                    left: -17px;
                    width: 14px;
                    border-top: 1px solid #b4b4b4;
                }

            }
        }
    }

    button.close-btn {
        display: block;
        background-color: #344766;
        color: #FFF;
        border: none;
        padding: 10px;
        cursor: pointer;
        width: 100%;
        text-align: left;
    }

    &.special-background {
        background-color: #29306e; // Example special color

        li.mainmenu {

            &:hover,
            &.active {
                background-color: #3d4a85; // Example special hover color
            }
        }
    }
}

.section {
    background-color: #fff;
    border-radius: .375rem;
    padding: 10px 20px;
    border: 1px solid #e2e8f0;


    .addNewBtn {
        border: none;
        background-color: transparent;
        border: 1px solid #cccccc;
        border-left: transparent;
        border-radius: 0 4px 4px 0;
        padding: 10px 10px 14px;
        line-height: 10px;
        color: #565656;
        font-size: 20px;
    }
}

// Dashboard page
.dashboard {
    .summary {
        .box {
            box-shadow: 0 0 5px -2px black;
            padding: 10px;
            border-radius: 5px;

            &:hover {
                box-shadow: 0 0 5px 0px black;
            }

            span {
                font-weight: 500;
            }
        }
    }

    .notification {
        ul {
            padding: 0;
        }

        li {
            padding: 7px;
            list-style: none;
            box-shadow: 0 0 5px -2px black;
            margin-bottom: 8px;

            &:hover {
                box-shadow: 0 0 5px 0px black;
            }
        }
    }

    .DInventory {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .box {
            box-shadow: 0 0 5px -2px black;
            height: 110px;
            width: 30%;
            padding: 10px;
            border-radius: 5px;

            &:hover {
                box-shadow: 0 0 5px 0px black;
                cursor: pointer;
            }

            .name {
                font-size: 16px;
                font-weight: 500;
            }

            .stock {
                margin-top: 7px;
                display: flex;
                gap: 15px;

                p {
                    margin: 0;
                    font-size: 14px
                }

                span {
                    justify-content: center;
                }
            }
        }

    }
}

//fot all table pagination
.MuiTablePagination-toolbar {
    align-items: center !important;

    p {
        margin-bottom: 0 !important;
    }
}

.css-9wcvca-MuiTableCell-root-MuiTablePagination-root {
    border: none !important;
}

.filetrBtn {
    background: transparent;
    color: #a73485;
    border: 1px solid #c4c4c4;
    padding: 3px 6px;
    border-radius: 3px;

    &:hover {
        border-color: #007dd1;
    }
}

.filterbox {
    position: absolute;
    background-color: #FFF;
    top: 50px;
    border-radius: 5px;
    box-shadow: 0 0 7px -3px black;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 15px;
    flex-direction: column;
    right: 0;
    z-index: 50;

    button.close {
        background-color: transparent;
        border: none;

    }
}

//marketing page
.marketing {
    .email {
        box-shadow: 0 0 5px -2px black;
        border-radius: 8px;

        &:hover {
            box-shadow: 0 0 5px 0px black;
            cursor: pointer;
        }
    }
}

// calendar page
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white; // Set the background color of the modal
    z-index: 1000; // Set a high z-index to ensure it appears on top
    padding: 60px; // Add padding or other styling as needed
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        input {
            margin-left: 10px;
        }
    }

    .modalBtn button {
        background-color: #64cdf7;
        padding: 8px;
        border-radius: 3px;
        border: none;

        &:hover {
            background-color: #48c0f0;
        }
    }
}

.rbc-today {
    background-color: #a9e7ff !important;
}

.ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.5) !important; // Set the background color and transparency of the overlay
    z-index: 999; // Set a z-index just below the modal
}

.ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// leads page
.ReactModal__Body--open {
    .newLeadCloseBtn {
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 23px;
        padding: 0;
        font-weight: 600;
    }
}

//Customer page
.customer-page {
    #newCustomerModal {
        .customer-group {
            position: relative;


            .newCustomerGroupWrapper {
                position: absolute;
                box-shadow: 0 0 10px -4px #000000;
                right: 0;
                z-index: 9;
                background-color: white;
                border-radius: 10px;
                border: 1px solid #cccccc;
                padding: 15px;
            }

            .css-13cymwt-control {
                border-radius: 4px 0 0 4px !important;
            }

            .addNewBtn {
                border: none;
                background-color: transparent;
                border: 1px solid #cccccc;
                border-left: transparent;
                border-radius: 0 4px 4px 0;
                padding: 10px 10px 14px;
                line-height: 10px;
                color: #565656;
                font-size: 20px;
            }
        }

        .modal-title {
            cursor: pointer;
            border-bottom: 1px solid #FFF;
            padding-bottom: 5px;

            &:hover {
                color: #2c2c2c;
                border-bottom: 1px solid #007dd1;

            }

            &.active {
                color: #007dd1;
                border-bottom: 1px solid #007dd1;

            }
        }

        .billingDetails {
            .title {
                margin: 0;
                color: #000;
                font-weight: 500;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                gap: 3px;

                span>svg {
                    cursor: pointer;
                    opacity: 0.7;
                    width: 18px;
                }
            }

            .link {
                cursor: pointer;
                margin: 0;
                color: #5f5f5f;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    .summary {
        .list {
            &:not(:last-child) {
                opacity: 0.7;
                border-right: #636363 1px solid;
            }

            p {
                display: flex;
                align-items: center;
                margin: 0;
                font-size: 16px;
            }
        }

        span {
            font-size: 22px;
            font-weight: 500;
            color: #000 !important;
            margin-right: 10px;
        }
    }
}

//required-star
.required-star {
    color: red;
    font-size: 18px;
    line-height: 16px;
    margin-right: 2px;
}

//customer-details page
.customer-details {

    .details-list {
        padding: 0;
        margin: 0;

        hr {
            margin: 0;
            color: #979797;
        }

        li {
            list-style: none;
            padding: 10px 25px;
            font-weight: 400;
            color: #3f3f3f;
            cursor: pointer;

            a {
                color: inherit;
            }

            .menu-icon {
                margin-right: 1rem;
                color: #979797;
            }

            &:hover {
                color: #000;

                .menu-icon {
                    color: #585858;
                }
            }

            &.active {
                color: #007ed3;

                .menu-icon {
                    color: #007ed3;
                }
            }
        }
    }

    .profile {
        .heading {
            padding: 15px 15px 0;

            p {
                cursor: pointer;
                padding: 0 10px 15px 10px;
                margin: 0;
                border-bottom: #FFF 1px solid;
                font-weight: 400;

                &:hover {
                    border-bottom: #007dd1 1px solid;
                }

                &.active {
                    color: #007dd1;
                    border-bottom: #007dd1 1px solid;
                }
            }
        }

        .body {
            padding: 15px 30px;

            .billingDetails {
                .title {
                    margin: 0;
                    color: #4d4d4d;
                    font-weight: 500;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    span>svg {
                        cursor: pointer;
                        opacity: 0.7;
                        width: 18px;
                    }
                }

                .link {
                    cursor: pointer;
                    margin: 0;
                    color: #5f5f5f;

                    &:hover {
                        color: #000;
                    }
                }
            }

        }

        .footer {
            padding: 0px 30px 10px;

        }
    }

    .contacts {
        #NewContactModal {
            hr {
                color: #919191;
            }
        }
    }
}

.form-control:focus {
    box-shadow: 0 0 0 0.05rem rgba(0, 125, 209, 0.25) !important;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.12rem rgba(0, 125, 209, 0.25) !important;
}

//for mui table css
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    color: #0000003b !important;

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
        color: #1976d2 !important;
    }
}

.css-1ex1afd-MuiTableCell-root {
    vertical-align: top !important;
}

//btn-light
.btn-light {
    background-color: transparent !important;
    border: 1px solid #bdbdbd;

    &:hover {
        background-color: #f3f0f0 !important;
    }
}

//eye icon foe password
.eye-icon-btn {
    cursor: pointer;
}

span.input-group-text {
    background-color: transparent !important;
}

//setUp customer groups list
.customer-Groups-list {

    .edit-icon,
    .delete-icon {
        cursor: pointer;
    }
}

// setup predefined replies editor
#newPredefinedRepliesModal {
    .quill .ql-container {
        max-height: calc(100% - 45px);
    }
}

//new currency modal
#newCurrencyModal {
    .form-label {
        margin-bottom: 3px !important;
        font-size: 15px !important;

        &.currency_code {
            span>svg {
                margin-left: 5px;
                margin-bottom: 2px;
                cursor: pointer;
                opacity: 0.8;
                width: 16px;
            }
        }
    }

    .form-check-label {
        font-size: 15px !important;
    }
}

.custom-alert-box {
    // // height: 40px !important;
    // padding: 8px 10px !important;
    padding: 10px 12px;
    position: absolute;
    top: 70px;
    left: 50%;
    -webkit-animation: slidedown 0.3s ease-in-out;
    animation: slidedown 0.3s ease-in-out;
    transform: translateY(0);
}

@keyframes slidedown {
    from {
        transform: translateY(-50px);
    }
}

// import data page
.import-sample-data-table {
    .table {
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
    }

    .table th,
    .table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
    }

    .table th {
        background-color: #fbfbfb;
        font-weight: 600;
    }

    .table tbody tr:hover {
        background-color: #f5f5f5;
    }
}

//new invoice page
.newInvoicePage {
    padding-bottom: 75px;

    .item-group {
        position: relative;


        .newCustomerGroupWrapper {
            position: absolute;
            box-shadow: 0 0 10px -4px #000000;
            right: 0;
            z-index: 9;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #cccccc;
            padding: 15px;
        }

        .css-13cymwt-control {
            border-radius: 4px 0 0 4px !important;
        }

        .addNewBtn {
            border: none;
            background-color: transparent;
            border: 1px solid #cccccc;
            border-left: transparent;
            border-radius: 0 4px 4px 0;
            padding: 10px 10px 14px;
            line-height: 10px;
            color: #565656;
            font-size: 20px;
        }
    }

    .newItemTable {
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
        border: 0.1px solid #e2e8f0;

        .hidden_unit_field {
            border: none;
            background: transparent;

            &:focus-visible {
                outline: none;
            }
        }

        th,
        td {
            text-align: left;
            background-color: #f8fafc00;
        }

        td {
            padding: 12px;
        }

        th {
            padding: 8px;
            border: 0.1px solid #e2e8f0;
            background-color: #f1f5f9;
            font-weight: 600;
        }

        .tbody tr:hover {
            background-color: #9e2626;
        }

        .new-item {
            background-color: #f8fafc !important;
        }


    }

    .subtotal-table {
        border-collapse: collapse;
        margin-bottom: 20px;

        th,
        td {
            border-bottom: 1px solid #ddd;
            padding: 10px;
        }

        th {
            font-weight: 500;
            text-align: end;

            &.tax {
                font-weight: 400;
            }

            &.discount {
                .css-1u9des2-indicatorSeparator {
                    display: none;
                }

                .css-1xc3v61-indicatorContainer {
                    padding: 0 5px;
                }


                .css-1fdsijx-ValueContainer {
                    padding: 0 0 0 5px;
                }

                .css-13cymwt-control {
                    border: none;

                }
            }
        }

        td {
            font-weight: 400;
            color: #868686;
            padding-right: 20px;
        }

    }

    .invoice-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 30px;
        margin-left: 220px;
        box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
        background: #fff;
        width: calc(100% - 220px);
        z-index: 5;
    }
}

.loading-overlay {
    position: fixed;
    top: 55px;
    left: 220px;
    width: calc(100% - 220px);
    height: calc(100% - 55px);
    background: rgba(0, 0, 0, 0.73);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

// invoice page
.invoicePage {

    //invoice overview section on invoice list page
    .invoiceOverview {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        .heading {
            .col {
                &.active>span {
                    border-bottom: 2px solid #007dd1;
                    font-weight: 400;
                    color: #007dd1;
                }

                span {
                    padding: 5px 15px 8px;
                    margin: 0;

                }
            }
        }

        .alert {
            font-size: 14px;
            padding: 5px;
        }

        .time-duration {
            .date {
                font-weight: 500;
                font-size: 15px;

                span {
                    font-weight: 400;
                    margin-left: 10px;
                    color: #6c757d;
                }
            }
        }

        .invoice-item-list {
            thead {
                th {
                    font-weight: 500;
                }
            }

            tbody {
                .name {
                    text-align: left;
                    font-size: 14px;

                    span {
                        font-size: 15px;
                        font-weight: 500;
                        color: #6c757d;
                    }
                }

                td:not(.name) {
                    text-align: right;
                    font-size: 15px;
                }
            }
        }

        .subtotal-list {
            table {

                th,
                td {
                    border-bottom: 1px solid #ddd;
                    padding: 10px;
                    font-size: 15px;
                }

                th {
                    font-weight: 500;
                    text-align: end;

                    &.tax {
                        font-weight: 400;
                    }

                }

                td {
                    font-weight: 400;
                    color: #868686;
                    padding-right: 20px;
                    text-align: right;
                }
            }
        }

        .tac {
            .title {
                font-weight: 500;
                color: #6c757d;
            }

            .note {
                font-size: 15px;
            }

        }

        .payments-table {
            th {
                font-weight: 500;
                color: #4d4d4d;
                text-align: left;
            }

            td {
                text-align: left;
                color: #727272;
                font-size: 15px;

                .action-icon:hover {
                    cursor: pointer;
                    color: #292828;
                }
            }
        }

    }

    // payment form
    .paymentForm {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        .heading {
            padding: 17px 0;

            h5 {
                font-size: 18px;
                color: #4d4d4d;
                margin: 0;
            }
        }

        .form-label {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.5px;
        }
    }
}

.proposalPage {
    .invoiceOverview {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
}

.newCreditNotePage {
    padding-bottom: 75px;

    .item-group {
        position: relative;


        .newCustomerGroupWrapper {
            position: absolute;
            box-shadow: 0 0 10px -4px #000000;
            right: 0;
            z-index: 9;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #cccccc;
            padding: 15px;
        }

        .css-13cymwt-control {
            border-radius: 4px 0 0 4px !important;
        }

        .addNewBtn {
            border: none;
            background-color: transparent;
            border: 1px solid #cccccc;
            border-left: transparent;
            border-radius: 0 4px 4px 0;
            padding: 10px 10px 14px;
            line-height: 10px;
            color: #565656;
            font-size: 20px;
        }
    }

    .newItemTable {
        border-collapse: collapse;
        width: 100%;
        border-radius: 5px;
        border: 0.1px solid #e2e8f0;

        .hidden_unit_field {
            border: none;
            background: transparent;

            &:focus-visible {
                outline: none;
            }
        }

        th,
        td {
            text-align: left;
            background-color: #f8fafc00;
        }

        td {
            padding: 12px;
        }

        th {
            padding: 8px;
            border: 0.1px solid #e2e8f0;
            background-color: #f1f5f9;
            font-weight: 600;
        }

        .tbody tr:hover {
            background-color: #9e2626;
        }

        .new-item {
            background-color: #f8fafc !important;
        }


    }

    .subtotal-table {
        border-collapse: collapse;
        margin-bottom: 20px;

        th,
        td {
            border-bottom: 1px solid #ddd;
            padding: 10px;
        }

        th {
            font-weight: 500;
            text-align: end;

            &.tax {
                font-weight: 400;
            }

            &.discount {
                .css-1u9des2-indicatorSeparator {
                    display: none;
                }

                .css-1xc3v61-indicatorContainer {
                    padding: 0 5px;
                }


                .css-1fdsijx-ValueContainer {
                    padding: 0 0 0 5px;
                }

                .css-13cymwt-control {
                    border: none;

                }
            }
        }

        td {
            font-weight: 400;
            color: #868686;
            padding-right: 20px;
        }

    }

    .create_note-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 30px;
        margin-left: 220px;
        box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
        background: #fff;
        width: calc(100% - 220px);
        z-index: 5;
    }

}


// Ticket
.ticket_header {

    //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    .heading {
        .col-2 {
            span {
                padding: 9px;
                margin: 0;
                font-weight: 500 !important;
            }

            &:last-child {
                margin-right: 0; // Remove the margin from the last .col element to prevent extra space
            }

            &.active {
                span {
                    border-bottom: 2px solid #007dd1;
                    font-weight: 400;
                    color: #007dd1;
                }
            }
        }

    }
}

// Credit Note Page
.creditNotePage {
    .creditNoteOverview {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        .heading {

            .col {
                &.active>span {
                    border-bottom: 2px solid #007dd1;
                    font-weight: 400;
                    color: #007dd1;
                }

                span {
                    padding: 5px 25px 9px;
                    margin: 0;
                }
            }
        }


        .alert {
            font-size: 14px;
            padding: 5px;
        }

        .time-duration {
            .date {
                font-weight: 500;
                font-size: 15px;

                span {
                    font-weight: 400;
                    margin-left: 10px;
                    color: #6c757d;
                }
            }
        }

        .credit-item-list {
            thead {
                th {
                    font-weight: 500;
                }
            }

            tbody {
                .name {
                    text-align: left;
                    font-size: 14px;

                    span {
                        font-size: 15px;
                        font-weight: 500;
                        color: #6c757d;
                    }
                }

                td:not(.name) {
                    text-align: right;
                    font-size: 15px;
                }
            }
        }

        .subtotal-list {
            table {

                th,
                td {
                    border-bottom: 1px solid #ddd;
                    padding: 10px;
                    font-size: 15px;
                }

                th {
                    font-weight: 500;
                    text-align: end;

                    &.tax {
                        font-weight: 400;
                    }

                }

                td {
                    font-weight: 400;
                    color: #868686;
                    padding-right: 20px;
                    text-align: right;
                }
            }
        }

        .tac {
            .title {
                font-weight: 500;
                color: #6c757d;
            }

            .note {
                font-size: 15px;
            }

        }

        .payments-table {
            th {
                font-weight: 500;
                color: #4d4d4d;
                text-align: left;
            }

            td {
                text-align: left;
                color: #727272;
                font-size: 15px;

                .action-icon:hover {
                    cursor: pointer;
                    color: #292828;
                }
            }
        }

    }

    // payment form
    .paymentForm {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        .heading {
            padding: 17px 0;

            h5 {
                font-size: 18px;
                color: #4d4d4d;
                margin: 0;
            }
        }

        .form-label {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.5px;
        }
    }
}

// Payment Details page
.paymentDetails {
    .section {
        border-radius: 4px;
        box-shadow: 0 0 2px rgba(99, 99, 99, 0.5);
    }

    .paymentReceipt {
        .address {
            strong {
                font-size: 14px;
            }
        }

        address {
            line-height: 20px;
            color: #6c757d;
        }

        h4 {
            font-weight: 400;
            color: #444444;
            letter-spacing: 1px;
            text-transform: uppercase;
        }

        .totalAmountBox {
            font-size: 18px;
            background-color: #1dac52;
        }

        .details-table {
            h5 {
                color: #3f3f3f;
            }

            table {
                border-collapse: collapse;
                width: 100%;
                border-radius: 5px;

                th,
                td {
                    border: 1px solid #ddd;
                    padding: 12px;
                    text-align: left;
                    color: #6c757d;
                    font-size: 15px;
                }

                th {
                    font-weight: 600;
                    color: #636363;

                    &.amountDue {
                        color: #dd2727;
                    }
                }

            }


        }
    }
}

//Expense page
.expensePage {
    .ExpenseOverview {
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

        .heading {
            .col {
                &.active>span {
                    border-bottom: 2px solid #007dd1;
                    font-weight: 400;
                    color: #007dd1;
                }

                span {
                    padding: 5px 15px 8px;
                    margin: 0;

                }
            }
        }

        .expense-reciept {
            color: #5c5c5c;
        }

        .attechReceiptBtn {
            width: 100%;
            text-align: center;
            padding: 28px;
            background: none;
            color: #1692e6;
            border: 1px dashed #1692e6;
            border-radius: 4px;

            &:hover {
                background-color: #ecf8ff;
            }

        }
    }
}

// Expense form
.expenseDetails {
    .expenseForm {
        .attechReceiptBtn {
            width: 100%;
            text-align: center;
            padding: 34px;
            background: none;
            color: #1692e6;
            border: 1px dashed #1692e6;
            border-radius: 7px;

            &:hover {
                background-color: #ecf8ff;
            }

        }

        .addNewBtn {
            border: none;
            background-color: transparent;
            border: 1px solid #cccccc;
            border-left: transparent;
            border-radius: 0 4px 4px 0;
            padding: 10px 10px 14px;
            line-height: 10px;
            color: #565656;
            font-size: 20px;
            font-weight: 500;

            &:hover {
                background-color: #f3f3f3;
            }
        }

        .item-group {
            .css-b62m3t-container {
                width: 100%;
            }

            .css-13cymwt-control {
                border-radius: 4px 0 0 4px !important;
            }
        }
    }

    .expense-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 30px;
        margin-left: 220px;
        box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
        background: #fff;
        width: calc(100% - 220px);
        z-index: 5;
    }
}


.contractDetails {
    .contract-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 30px;
        margin-left: 220px;
        box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
        background: #fff;
        width: calc(100% - 220px);
        z-index: 5;
    }
}


label {
    font-weight: 500;
}

//email-template
.mergeFieldsList {
    .merge-field {
        &:hover {
            color: rgb(61, 168, 255); // Change this to the color you want
        }
    }
}

//multi input field in support Department
.multi-email-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
    cursor: text;

    &:focus-within {
        border-color: #66afe9;
        box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
    }

    input {
        border: none;
        outline: none;
        flex: 1;
        min-width: 50px;
        padding: 2px;
        font-size: 14px;
        margin: 2px 0;
        max-height: 17px;
    }

    .tag {
        display: flex;
        align-items: center;
        background-color: #e0e0e0;
        border-radius: 4px;
        padding: 3px 8px;
        margin: 3px;
        font-size: 14px;

        .remove {
            margin-left: 8px;
            cursor: pointer;
            color: #888;
            font-size: 18px;
            line-height: 1;

            &:hover {
                color: #555;
            }
        }
    }
}

.div {
    .invoice-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 10px 30px;
        margin-left: 220px;
        box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
        background: #fff;
        width: calc(100% - 220px);
        z-index: 5;
    }
}


// reply
.reply {
    .title {
        border-right: #ced0d2 solid 1px;
    }
}


// notification
// Notification.scss
.notification-container {
    position: fixed;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1000;

    .notification {
        background-color: white;
        color: #1dac52; // Default text color
        border: 1px solid #1dac52;
        padding: 15px;
        margin-top: 10px; // Add spacing between notifications
        border-radius: 5px;
        position: relative;
        transition: opacity 0.5s ease;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        animation: slideIn 0.5s forwards; // Slide in animation

        &.success {
            border-color: #1dac52;
        }

        &.error {
            border-color: #fc0303; // Error border color
            color: #fc0303; // Error text color

            .close-btn {
                color: #fc0303; // Close button color for error
            }

            .time-bar {
                background-color: #fc0303; // Error time bar color
            }
        }

        .close-btn {
            position: absolute;
            top: -8px;
            right: -4px;
            background: none;
            border: none;
            color: #1dac52;
            font-size: 20px;
            cursor: pointer;
        }

        .time-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 5px;
            background-color: #1dac52; // Default time bar color
            width: 100%;
            animation: shrink 3s linear forwards;
        }

        &.hide {
            animation: slideOut 0.5s forwards;
        }
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes shrink {
    from {
        width: 100%;
    }

    to {
        width: 0;
    }
}



.row {

    .item-groups {
        position: relative;

        .newCustomerGroupWrapper {
            width: 500%;
            /* Adjust the width to 100% or your desired value */
            box-shadow: 0 0 10px -4px #000000;
            right: 0;
            z-index: 9;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #cccccc;
            padding: 15px;
        }

        .css-13cymwt-control {
            border-radius: 4px 0 0 4px !important;
        }

        .addNewBtn {
            border: none;
            background-color: transparent;
            border: 1px solid #cccccc;
            border-left: transparent;
            border-radius: 0 4px 4px 0;
            padding: 10px 10px 14px;
            line-height: 10px;
            color: #565656;
            font-size: 20px;
        }
    }
}



// file upload
// .dropzoneDragArea {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 40px;
//     margin: 10px;
//     border: 1px dashed #93c5fd;
//     border-radius: 6px;
//     background-color: #fff;
//     transition: background-color 0.3s ease;
//     position: relative;

//     &.dz-clickable {
//         cursor: pointer;
//     }

//     &.drag-over {
//         background-color: #e0e0e0;
//     }

//     &.uploading {
//         background-color: #d4edda;
//         animation: pulse 2s infinite;
//     }

//     span {
//         font-size: 16px;
//         color: #2563eb;
//     }

//     &:hover {
//         background-color: #f0f9ff;
//     }
// }

.dropzoneDragArea {
    display: flex;
    flex-direction: column; // Align content vertically
    justify-content: center;
    align-items: center;
    padding: 10px;
    //border: 1px dashed #93c5fd;
    border: 1px dashed #c7c8c9;
    border-radius: 6px;
    background-color: #fff;
    transition: background-color 0.3s ease;
    position: relative;

    &.dz-clickable {
        cursor: pointer;
    }

    &.drag-over {
        background-color: #e0e0e0;
    }

    &.uploading {
        background-color: #d4edda;
        animation: pulse 2s infinite;
    }

    span {
        font-size: 16px;
        //color: #2563eb;
        color: #fc96dd;
        margin-bottom: 8px; // Space between icon and text
    }

    &:hover {
        background-color: #f0f9ff;
    }

    .dropzone-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .upload-icon {
            font-size: 2rem; // Adjust icon size
            color: #a73485;
            margin-bottom: 10px; // Space below the icon
        }

        .upload-text {
            font-size: 1.2rem;
            color: #a73485;
            font-weight: 500;
            margin-bottom: 5px; // Space between text and file types
        }

        .accepted-file-types {
            font-size: 0.9rem;
            color: #6b7280; // Lighter gray color for file types
        }
    }

}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.files-list {
    margin-top: 20px;
}

.file-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 10;

    span {
        width: 35%;
    }

    .progress-bar {
        width: 100%;
        background-color: #f1f1f1;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 10px;

        .progress {
            width: 0;
            height: 5px;
            background-color: #a73485;
            transition: width 0.5s ease;
        }

        .progressing {
            width: 100%;
        }
    }

    .remove-btn {
        background-color: #ff4d4d;
        border: none;
        color: white;
        padding: 5px 10px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #ff3333;
        }
    }
}

.save-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
}


.progress-container {
    position: relative;

    .progress-bar {
        width: 100%;
    }

    .progress-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(0, 0, 0) !important;
        font-weight: bold; // Optional: Makes the text bold for better visibility
        z-index: 1; // Ensure label is above progress bar
    }

    .progress-bar-black-label {
        color: black !important;
    }
}

// YourComponent.module.scss

.image-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .image-popup-content {
        position: relative;

        img {
            max-width: 100vw;
            max-height: 100vh;
            object-fit: contain;
        }

        .image-popup-close {
            position: absolute;
            top: -30px;
            right: -5px;
            color: white;
            font-size: 24px;
            cursor: pointer;
        }
    }
}
