/* Import Fira Sans font */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700;800;900&display=swap');

/* Import Outfit font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Your other CSS styles go here */


.login-page {
    height: 100vh;
    background-color: #006eb7a4;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-card {
        background-color: #FFFFFF;
        border-radius: 10px;
        width: 500px;

    }

    .form-control.password {
        border-radius: 4px 0 0 4px !important;
    }

    .addNewBtn {
        border: none;
        background-color: transparent;
        border: 1px solid #cccccc;
        border-left: transparent;
        border-radius: 0 4px 4px 0;
        padding: 10px 10px 14px;
        line-height: 10px;
        color: #565656;
        font-size: 20px;
    }

    &.special-login-background {
        background-color: #29306e;
    }
}

a {
    text-decoration: none;
}

.cp {
    cursor: pointer;
}

.form-label {
    margin-bottom: 0.25rem;
}

.color-light {
    color: #6c757d
}